import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import ReadingProgressBar from "../../../packages/ReadingProgressBar/src";
import { Heading, P } from "../../../packages/Typography/src";
import AuthorCard from "../../../packages/AuthorCard/src";
import Container from "../../../packages/Container/src";
import Image from "../../../packages/Image/src";

var BlogPostPattern = function BlogPostPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Container, {
    size: "xs",
    py: "2.5rem"
  }, React.createElement(AuthorCard, {
    title: "Author Name",
    date: "12 May 2019",
    src: "https://www.datocms-assets.com/5436/1541434376-daniel.png?w=300&h=300&fit=facearea&facepad=2&auto=format&fm=jpg",
    alt: "Daniel",
    size: "4rem"
  })), React.createElement(ReadingProgressBar, null, React.createElement(Container, {
    size: "xs"
  }, React.createElement(Heading, {
    as: "h1",
    size: "xxxl",
    mt: "0"
  }, "Main blog post heading"), React.createElement(P, {
    size: "xl",
    mb: "0"
  }, "This is a lead paragraph for this blog post. This is a short introduction that aims to get the reader interested in this article. Keep this paragraph short and informative.")), React.createElement(Container, {
    size: "sm",
    pt: "2.5rem"
  }, React.createElement(Image, {
    src: "https://www.datocms-assets.com/5436/1554538110-cju57uqdi0000x2xbei99bcgrimage.png?w=1200&h=675&fit=fill&auto=compress&fill=solid&fill-color=FFF",
    width: 1200,
    height: 675,
    alt: "Sample image 1"
  })), React.createElement(Container, {
    size: "xs",
    pt: "0.75rem",
    pb: "2.5rem"
  }, React.createElement(P, {
    size: "sm",
    muted: true,
    mb: "0"
  }, "This is a caption for the image above.")), React.createElement(Container, {
    size: "xs"
  }, React.createElement(P, {
    size: "md"
  }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."), React.createElement(P, {
    size: "md"
  }, "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."), React.createElement(Heading, {
    as: "h2",
    size: "xxl"
  }, "Heading h2"), React.createElement(P, {
    size: "md"
  }, "Tincidunt dui ut ornare lectus sit amet est placerat in. Adipiscing enim eu turpis egestas pretium aenean. Sem nulla pharetra diam sit amet nisl. Enim sit amet venenatis urna cursus eget nunc scelerisque viverra. Hendrerit dolor magna eget est lorem ipsum dolor sit."), React.createElement(Heading, {
    as: "h3",
    size: "xl"
  }, "Heading h3"), React.createElement(P, {
    size: "md"
  }, "Gravida rutrum quisque non tellus orci ac auctor augue mauris. Viverra ipsum nunc aliquet bibendum enim facilisis. Neque sodales ut etiam sit amet nisl purus in. Sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus. Ipsum a arcu cursus vitae.")), React.createElement(Container, {
    size: "xs",
    pt: "2.5rem"
  }, React.createElement(Image, {
    src: "https://www.datocms-assets.com/5436/1554538110-cju57uqdi0000x2xbei99bcgrimage.png?w=1200&h=675&fit=fill&auto=compress&fill=solid&fill-color=FFF",
    width: 1200,
    height: 675,
    alt: "Sample image 2"
  })), React.createElement(Container, {
    size: "xs",
    pt: "0.75rem",
    pb: "2.5rem"
  }, React.createElement(P, {
    size: "sm",
    color: "grey.600",
    mt: "0.75rem",
    mb: "0"
  }, "This is a caption for the image above.")), React.createElement(Container, {
    size: "xs",
    pb: "5rem"
  }, React.createElement(P, {
    size: "md"
  }, "Tincidunt dui ut ornare lectus sit amet est placerat in. Adipiscing enim eu turpis egestas pretium aenean. Sem nulla pharetra diam sit amet nisl. Enim sit amet venenatis urna cursus eget nunc scelerisque viverra. Hendrerit dolor magna eget est lorem ipsum dolor sit."))));
};

export default BlogPostPattern;