import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable import/max-dependencies */

/* eslint-disable import/no-namespace */
import React from "react";
import { ThemeProvider } from "styled-components";
import Seo from "../Seo";
import GlobalStyle from "../GlobalStyle";
import * as theme from "../../../packages/theme/src";
import iconSpriteSheet from "../../images/datastory-icons.svg";
import flagSpriteSheet from "../../images/datastory-flags.svg";
import Flex from "../../../packages/Flex/src";
import Div from "../../../packages/Div/src";
import Container from "../../../packages/Container/src";
import Logo from "../../../packages/Logo/src";
import Button from "../../../packages/Button/src";
import Link from "../../../packages/Link/src";
import Icon from "../../../packages/Icon/src";
import Divider from "../../../packages/Divider/src";
import { P } from "../../../packages/Typography/src";
import { DrawerContainer, DrawerToggle, Drawer } from "../../../packages/Drawer/src";
import Modal from "../../../packages/Modal/src";

var customTheme = _objectSpread({}, theme, {}, theme.typography, {
  spriteSheet: {
    icon: iconSpriteSheet,
    flag: flagSpriteSheet
  }
});

var PatternLayout = function PatternLayout(_ref) {
  var children = _ref.children,
      noNavigation = _ref.noNavigation,
      minimal = _ref.minimal,
      navColor = _ref.navColor,
      _ref$logoOutlined = _ref.logoOutlined,
      logoOutlined = _ref$logoOutlined === void 0 ? false : _ref$logoOutlined,
      _ref$logoColor = _ref.logoColor,
      logoColor = _ref$logoColor === void 0 ? "white" : _ref$logoColor;
  return React.createElement(ThemeProvider, {
    theme: customTheme
  }, React.createElement(React.Fragment, null, React.createElement(Seo, null), React.createElement(GlobalStyle, null), !noNavigation && React.createElement(Div, {
    boxShadow: minimal ? "none" : "md",
    py: "0.75rem",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bg: minimal ? "transparent" : "white",
    color: navColor,
    zIndex: 999
  }, React.createElement(Container, null, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(Div, {
    flex: ["1 1 auto", null, "none"],
    pr: "1.25rem"
  }, React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    style: {
      verticalAlign: "middle",
      display: "inline-block"
    }
  }, React.createElement(Logo, {
    outlined: logoOutlined,
    color: logoColor
  }))), !minimal && React.createElement(Div, {
    flex: "1 1 auto",
    display: ["none", null, "block"]
  }, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    mr: "1.25rem"
  }, "Data stories"), React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    mr: "1.25rem"
  }, "Topics"), React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    mr: "1.25rem"
  }, "Charts"), React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    mr: "1.25rem"
  }, "Data"), React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600"
  }, "Blog"))), !minimal && React.createElement(Flex, {
    alignItems: "center",
    display: ["none", "block"]
  }, React.createElement(Link, {
    href: "/",
    variant: "stealth",
    mr: "1.25rem",
    fontWeight: "600"
  }, "About"), React.createElement(Button, {
    borderRadius: "circle",
    width: "md",
    px: "0",
    mr: "0.75rem"
  }, React.createElement(Icon, {
    name: "search"
  })), React.createElement(Modal, {
    disclosure: React.createElement(Button, {
      borderRadius: "circle",
      width: "md",
      px: "0"
    }, React.createElement(Icon, {
      name: "translate"
    }))
  }, React.createElement(Div, {
    maxWidth: "15rem"
  }, React.createElement(P, {
    mb: "0"
  }, "We are currently working on translating datastory.org. Get involved and help us!"))), React.createElement(Button, {
    px: "1rem",
    borderRadius: "md",
    bg: "grey.50",
    mx: "0.75rem"
  }, "Subscribe")), React.createElement(Div, {
    flex: "none"
  }, React.createElement(DrawerContainer, {
    name: "DrawerContainer"
  }, React.createElement(DrawerToggle, {
    as: Button,
    borderRadius: "circle",
    width: "md",
    px: "0"
  }, React.createElement(Icon, {
    name: "menu"
  })), React.createElement(Drawer, {
    color: "text"
  }, React.createElement(Flex, {
    py: "0.75rem",
    px: "1.25rem",
    justifyContent: "space-between"
  }, React.createElement(Div, {
    flex: "none"
  }, React.createElement(Logo, null)), React.createElement(Div, {
    flex: "none"
  }, React.createElement(DrawerToggle, {
    as: Button,
    borderRadius: "circle",
    width: "md",
    px: "0"
  }, React.createElement(Icon, {
    name: "close"
  })))), React.createElement(Divider, {
    color: "grey.100"
  }), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Data stories"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Topics"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Charts"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Data"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Blog"), React.createElement(Divider, {
    color: "grey.100"
  }), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Newsletter"), React.createElement(Divider, {
    color: "grey.100"
  }), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "About"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Services"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Team"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Donate"), React.createElement(Divider, {
    color: "grey.100"
  }))))))), children));
};

export default PatternLayout;